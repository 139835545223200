// utils/AnimatedDiv.tsx

import React, { useRef } from "react";
import { motion, Variants, useAnimation, useInView } from "framer-motion";

type AnimatedDivProps = {
  children: React.ReactNode;
  variants?: Variants;
  transition?: any;
  className?: string;
  isAnimationDisabled?: boolean;
  margin?: any;
};

const defaultVariants: Variants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0 },
};

const AnimatedDiv: React.FC<AnimatedDivProps> = ({
  children,
  variants = defaultVariants,
  transition = { delay: 0.3, duration: 0.6 },
  className,
  isAnimationDisabled,
  margin = "-100px",
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const inView = useInView(ref, { once: true, margin });
  const controls = useAnimation();

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  if (isAnimationDisabled) {
    return (
      <div ref={ref} className={className}>
        {children}
      </div>
    );
  }

  return (
    <motion.div
      ref={ref}
      variants={variants}
      initial="hidden"
      animate={controls}
      transition={transition}
      className={className}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedDiv;

import { RouterProvider, createBrowserRouter } from "react-router-dom";
import HomePage from "./pages/HomePage";
import BookPageCalendar from "./pages/BookPageCalendar";
import BookPageService from "./pages/BookPageService";
import { useState } from "react";
import BookPageDetail from "./pages/BookPageDetail";
import BookPageConfirm from "./pages/BookPageConfirm";
import { IUser } from "./types/types";
import ErrorPage from "./pages/ErrorPage";
import { getLanguage } from "./utils/utils";
import { translateString } from "./utils/languageTranslations";

function App() {
  const [progressBar, setProgressBar] = useState(0);
  const [userData, setUserData] = useState<IUser>({
    serviceName: null,
    serviceId: null,
    serviceDuration: null,
    date: null,
    time: null,
    name: null,
    email: null,
    phone: null,
    comments: null,
  });
  const [languageState, setLanguageState] = useState(getLanguage() || "et");

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <HomePage
          languageState={languageState}
          setLanguageState={setLanguageState}
        />
      ),
    },
    {
      path: "/en/booking-service-select",
      element: (
        <BookPageService
          progressBar={progressBar}
          setProgressBar={setProgressBar}
          userData={userData}
          setLanguageState={setLanguageState}
          setUserData={setUserData}
        />
      ),
    },
    {
      path: "/et/broneeri-teenuse-valik",
      element: (
        <BookPageService
          progressBar={progressBar}
          setProgressBar={setProgressBar}
          userData={userData}
          setLanguageState={setLanguageState}
          setUserData={setUserData}
        />
      ),
    },
    {
      path: "/ru/vybor-bronirovaniya-uslugi",
      element: (
        <BookPageService
          progressBar={progressBar}
          setProgressBar={setProgressBar}
          userData={userData}
          setLanguageState={setLanguageState}
          setUserData={setUserData}
        />
      ),
    },
    {
      path: "/uk/vybir-poslugy-bronyuvannya",
      element: (
        <BookPageService
          progressBar={progressBar}
          setProgressBar={setProgressBar}
          userData={userData}
          setUserData={setUserData}
          setLanguageState={setLanguageState}
        />
      ),
    },
    {
      path: `/:lang/${translateString("booking-date-select")}`,
      element: (
        <BookPageCalendar
          progressBar={progressBar}
          setProgressBar={setProgressBar}
          userData={userData}
          setUserData={setUserData}
          languageState={languageState}
          setLanguageState={setLanguageState}
        />
      ),
    },
    {
      path: `/:lang/${translateString("booking-details")}`,
      element: (
        <BookPageDetail
          progressBar={progressBar}
          setProgressBar={setProgressBar}
          userData={userData}
          setUserData={setUserData}
          languageState={languageState}
          setLanguageState={setLanguageState}
        />
      ),
    },
    {
      path: `/:lang/${translateString("booking-confirmation")}`,
      element: (
        <BookPageConfirm
          progressBar={progressBar}
          setProgressBar={setProgressBar}
          userData={userData}
          languageState={languageState}
          setLanguageState={setLanguageState}
        />
      ),
    },
    {
      path: "/:lang",
      element: (
        <HomePage
          languageState={languageState}
          setLanguageState={setLanguageState}
        />
      ),
    },
    {
      path: "*",
      element: <ErrorPage />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;

import { useEffect } from "react";
import Contact from "../components/contact/Contact";
import ProgressBar from "../components/progressBar/ProgressBar";
import TopBarMinified from "../components/topBarMinified/TopBarMinified";
import BookingDetail from "../components/bookingDetail/BookingDetail";
import { IUser, Language } from "../types/types";
import { useNavigate } from "react-router-dom";
import HomePage from "./HomePage";

const BookPageDetail = ({
  progressBar,
  setProgressBar,
  userData,
  setUserData,
  languageState,
  setLanguageState,
}: {
  progressBar: number;
  setProgressBar: (progressBar: number) => void;
  userData: IUser;
  setUserData: (userData: IUser) => void;
  languageState: Language;
  setLanguageState: (language: Language) => void;
}) => {
  const navigate = useNavigate();
  const { serviceName, serviceId, date, time } = userData;
  const shouldNavigate = !serviceName || !serviceId || !date || !time;

  useEffect(() => {
    shouldNavigate && navigate("/");
    setProgressBar(2);
  }, []);

  if (shouldNavigate) {
    return (
      <HomePage
        languageState={languageState}
        setLanguageState={setLanguageState}
      />
    );
  }

  return (
    <>
      <TopBarMinified />
      <ProgressBar progressBar={progressBar} />
      <BookingDetail userData={userData} setUserData={setUserData} />
      <Contact />
    </>
  );
};

export default BookPageDetail;

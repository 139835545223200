import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react";
import { HelmetProvider } from "react-helmet-async";
import MetaSEOComponent from "./utils/MetaSEOComponent";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import SchemaMarkup from "./utils/SchemaMarkup";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    <HelmetProvider>
      <MetaSEOComponent />
      <SchemaMarkup />
      <Analytics />
      <SpeedInsights />
      <App />
    </HelmetProvider>
  </QueryClientProvider>
);

import "./BookingCalendar.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Link } from "react-router-dom";
import { IUser, Time } from "../../types/types";
import { useEffect, useState } from "react";
import {
  convertMinutesToHours,
  getDateMonthsAhead,
  getLanguage,
  getUserDataLS,
} from "../../utils/utils";
import { ClipLoader } from "react-spinners";
import { translateString } from "../../utils/languageTranslations";
import { fetchData } from "../../utils/fetchData";
import { useQuery } from "@tanstack/react-query";

const calendarLocales = {
  et: "et",
  en: "en",
  ru: "ru",
  uk: "uk",
};

const Booking = ({
  userData,
  setUserData,
}: {
  userData: IUser;
  setUserData: (userData: IUser) => void;
}) => {
  const { serviceId, date, serviceName, serviceDuration } = userData;

  const [availableDates, setAvailableDates] = useState([]);
  const language = getLanguage();
  const { serviceNameLS, serviceIdLS, serviceDurationLS } = getUserDataLS();

  const {
    isLoading,
    data: times = [],
    error,
  } = useQuery({
    queryKey: ["allTimeslots", serviceId, date],
    queryFn: () =>
      fetchData(`timeslots?serviceId=${serviceId ?? serviceIdLS}&date=${date}`),
    staleTime: 1000 * 60, // 1 minute
    gcTime: 60 * 1000, // 1 minute
    enabled: !!date,
  });

  const handleTimeSelect = (time: Time) => {
    setUserData({ ...userData, time });
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    const getAvailableDates = async () => {
      try {
        const response = await fetchData(
          `timeslots/available-dates?serviceId=${serviceId ?? serviceIdLS}`
        );

        setUserData({
          ...userData,
          date: response[0],
        });
        setAvailableDates(response);
      } catch (error) {
        console.log("🚀 ~ error:", error);
      }
    };

    getAvailableDates();
  }, []);

  const formatDateToYYYYMMDD = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const getAvailableMonths = (availableDates: string[]): number[] => {
    const availableMonths = new Set<number>();
    availableDates.forEach((date) => {
      return availableMonths.add(new Date(date).getMonth());
    });

    return Array.from(availableMonths);
  };

  if (error) {
    return <div>Something went wrong...</div>;
  }

  return (
    <div className="booking">
      <div className="booking-calendar">
        <div className="booking-calendar-view">
          <Calendar
            next2Label={null}
            prev2Label={null}
            maxDate={getDateMonthsAhead(3)}
            minDetail="year"
            tileDisabled={({ date, view }) => {
              if (view === "year") {
                return !getAvailableMonths(availableDates).includes(
                  date.getMonth()
                );
              }

              return !availableDates.includes(formatDateToYYYYMMDD(date));
            }}
            value={date}
            minDate={new Date()}
            onChange={(value: any) => {
              return setUserData({
                ...userData,
                date: formatDateToYYYYMMDD(value),
              });
            }}
            locale={calendarLocales[language]}
          />
        </div>
        <div className="booking-calendar-dates">
          <div className="booking-calendar-dates-top">
            <p>
              {translateString("Selected service")}:{" "}
              <span>{translateString(serviceName ?? serviceNameLS)}</span>
            </p>
            <p>
              {translateString("Duration")}:{" "}
              <span>
                {convertMinutesToHours(
                  serviceDuration ?? serviceDurationLS,
                  "long"
                )}
              </span>{" "}
            </p>
            <p>
              {translateString("Date")}: <span>{date}</span>{" "}
            </p>
          </div>
          <div className="booking-calendar-dates-bottom">
            <div>
              <h3>
                {translateString("Available spots for: ")} <span>{date}</span>
              </h3>
            </div>
            <ul>
              {isLoading ? (
                <>
                  <ClipLoader color="#495E57" />
                  <p className="booking-calendar-search-text">
                    {translateString("Searching available dates")}...
                  </p>
                </>
              ) : (
                times.map((time, index) => (
                  <div key={index} className="booking-calendar-dates-li">
                    <Link
                      className="link"
                      onClick={() => handleTimeSelect(time)}
                      to={`/${language}/${translateString("booking-details")}`}
                    >
                      <li key={time} className="booking-calendar-dates-li">
                        {time.startTime}
                      </li>
                    </Link>
                  </div>
                ))
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Booking;

import { Link } from "react-router-dom";
import "./BookingConfirm.css";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { IUser } from "../../types/types";
import { generateGoogleCalendarLink } from "../../utils/utils";
import EventIcon from "@mui/icons-material/Event";
import { translateString } from "../../utils/languageTranslations";
import { useEffect } from "react";

const BookingConfirm = ({ userData }: { userData: IUser }) => {
  const { name, serviceName, date, time, email } = userData;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bookingConfirm">
      <div className="bookingConfirmWrapper">
        <h1>{translateString("Booking Confirmed")}</h1>
        <p>
          {translateString("Thank you")} <span>{name}</span>{" "}
          {translateString("for booking with us.")} <br />
          <br />
          {translateString("We look forward to seeing you for your")}{" "}
          <span>{translateString(serviceName)}</span> {translateString("on")}{" "}
          <span>{date}</span> {translateString("at")}{" "}
          <span>
            {time.startTime} - {time.endTime}.
          </span>
        </p>
        <p>
          {translateString("We've sent you an email at")} <span>{email}</span>{" "}
          {translateString("with the booking confirmation")}.
        </p>
        <a
          className="link bookingConfirm-calendar"
          target="_blank"
          rel="noreferrer"
          href={generateGoogleCalendarLink(
            "NailDay visit",
            date,
            time.startTime,
            time.endTime,
            `NailDay visit for ${serviceName}`,
            "Kopli"
          )}
          aria-label="Add to your google calendar"
        >
          {translateString("Click here to add to your calendar")}
          <EventIcon />
        </a>
        <iframe
          className="bookingConfirm__svg"
          title="Confirmed"
          src="https://lottie.host/embed/c61ddd09-c05a-4551-b156-2c14fceb7b6f/UqlHI3bFqy.json"
        ></iframe>
        <Link className="bookingConfirm-back link" to="/">
          <KeyboardBackspaceIcon />
          {translateString("Back to Home")}
        </Link>
      </div>
    </div>
  );
};

export default BookingConfirm;

import "./BookingService.css";
import service1 from "../../assets/service1.png";
import service2 from "../../assets/service2.png";
import service3 from "../../assets/service3.png";
import service4 from "../../assets/service4.png";
import service5 from "../../assets/service5.png";
import service6 from "../../assets/service6.png";
import serviceGeneral from "../../assets/service-manicure.jpg";
import { Link } from "react-router-dom";
import { IUser } from "../../types/types";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  convertMinutesToHours,
  getLanguage,
  setUserDataLS,
} from "../../utils/utils";
import { translateString } from "../../utils/languageTranslations";
import { useQuery } from "@tanstack/react-query";
import { fetchData } from "../../utils/fetchData";
import AnimatedDiv from "../../utils/AnimatedDiv";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip as ReactTooltip } from "react-tooltip";
import useMobile from "../../hooks/useMobile";

const ServiceCard = ({
  service,
  onSelect,
  language,
}: {
  service: any;
  onSelect: (name: string, id: string, duration: number) => void;
  language: string;
}) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const isMobile = useMobile();

  const handleTouch = (e) => {
    setTooltipVisible(true);
  };

  return (
    <AnimatedDiv
      transition={{
        delay: 0.1,
        duration: 0.5,
      }}
      className="bookingService-box"
      key={service._id}
    >
      <div className="bookingService-box-img">
        <img
          loading="lazy"
          src={
            service.name === "Manicure"
              ? service1
              : service.name === "Material Removal"
              ? service2
              : service.name === "Combined Manicure"
              ? service3
              : service.name === "Hardware Manicure"
              ? service4
              : service.name === "Nail Extension"
              ? service5
              : service.name === "Long Nail Correction"
              ? service6
              : serviceGeneral
          }
          alt="Nail service"
        />
      </div>
      <div className="bookingSerivce-box-content">
        <h2>{translateString(service.name)}</h2>
        <p className="bookingService-box-content-duration">
          {convertMinutesToHours(service.duration)}
          <InfoIcon
            data-tooltip-id={`duration-tooltip-${service._id}`}
            className="bookingService-box-duration-icon"
            onTouchStart={handleTouch}
          />
        </p>
        <p className="bookingService-box-content-price">{service.price} €</p>
        <p className="bookingService-box-content-desc">
          {translateString(service.description)}.
        </p>
        <Link
          onClick={() => onSelect(service.name, service._id, service.duration)}
          className="bookingService-box-button"
          to={`/${language}/${translateString("booking-date-select")}`}
        >
          {translateString("Select")}
        </Link>
      </div>
      {isMobile ? (
        isTooltipVisible && (
          <ReactTooltip
            id={`duration-tooltip-${service._id}`}
            place="bottom"
            content={translateString(service.longDescription)}
            className="bookingService-box-tooltip"
          />
        )
      ) : (
        <ReactTooltip
          id={`duration-tooltip-${service._id}`}
          place="bottom"
          content={translateString(service.longDescription)}
          className="bookingService-box-tooltip"
        />
      )}
    </AnimatedDiv>
  );
};

const BookingService = ({
  userData,
  setUserData,
}: {
  userData: IUser;
  setUserData: (userData: IUser) => void;
}) => {
  const language = getLanguage();

  const {
    isLoading,
    data: services,
    error,
  } = useQuery({
    queryKey: ["allServices"],
    queryFn: () => fetchData("services"),
    staleTime: Infinity,
    gcTime: 60 * 1000 * 60 * 24, // 1 day
  });

  const handleServiceSelect = (
    serviceName: string,
    serviceId: string,
    serviceDuration: number
  ) => {
    setUserData({ ...userData, serviceName, serviceId, serviceDuration });
    setUserDataLS({
      ...userData,
      serviceNameLS: serviceName,
      serviceIdLS: serviceId,
      serviceDurationLS: serviceDuration,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    setUserData({
      name: "",
      phone: "",
      email: "",
      comments: "",
      serviceName: "",
      serviceDuration: null,
      serviceId: "",
      date: "",
      time: { startTime: "", endTime: "", startTimeslotIds: [] },
    });
  }, [setUserData]);

  if (error) {
    return <div>Something went wrong...</div>;
  }

  return (
    <div className="bookingService">
      <div className="bookingService-services">
        {isLoading &&
          Array.from({ length: 6 }).map((_, index) => (
            <Skeleton
              key={index}
              height={300}
              width={250}
              borderRadius={"30px"}
            />
          ))}
        {services?.map((service, index) => (
          <ServiceCard
            key={index}
            service={service}
            onSelect={handleServiceSelect}
            language={language}
          />
        ))}
      </div>
    </div>
  );
};

export default BookingService;

import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { setLanguage, setLanguageStateFromUrl } from "../utils/utils";
import { Language } from "../types/types";
import { VALID_LANGUAGES } from "../constants/constants";

const useLanguageHandler = (
  languageState: Language,
  setLanguageState: (language: Language) => void
) => {
  const { lang } = useParams<{ lang: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (lang === "et" || !lang) {
      setLanguage("et");
      setLanguageState("et");
      navigate("/", { replace: true });
      return;
    }

    if (lang && VALID_LANGUAGES.includes(lang) && lang !== languageState) {
      if (lang !== "et") {
        setLanguage(lang as Language);
        setLanguageState(lang as Language);
        navigate(`/${lang}`, { replace: true });
      }
      return;
    }

    if (!lang && languageState && languageState !== "et") {
      navigate(`/${languageState}`, { replace: true });
      return;
    }

    if (lang && !VALID_LANGUAGES.includes(lang)) {
      navigate(`/`, { replace: true });
      return;
    }

    setLanguageStateFromUrl(
      { pathname: lang ? `/${lang}` : "/" },
      setLanguageState
    );
  }, [lang, languageState, setLanguageState, navigate]);
};

export default useLanguageHandler;

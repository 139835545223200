import { useState, useEffect } from "react";

const useScrollPosition = (threshold = window.innerHeight) => {
  const [isScrolledDown, setIsScrolledDown] = useState(false);

  const handleScroll = () => {
    const scrolledDown = window.scrollY >= threshold;
    // Only update state if the value has changed
    if (scrolledDown !== isScrolledDown) {
      setIsScrolledDown(scrolledDown);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    // Initial check in case the user is already scrolled past the threshold on mount
    handleScroll();

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [threshold, isScrolledDown]);

  return isScrolledDown;
};

export default useScrollPosition;

import { Helmet } from "react-helmet-async";
import { translateString } from "./languageTranslations";
import { getLanguage } from "./utils";

const MetaSEOComponent = () => {
  const language = getLanguage();
  return (
    <Helmet>
      <html lang={language} />
      <link rel="alternate" hrefLang="et" href="https://www.nailday.ee" />
      <link rel="alternate" hrefLang="en" href="https://www.nailday.ee/en" />
      <link rel="alternate" hrefLang="ru" href="https://www.nailday.ee/ru" />
      <link rel="alternate" hrefLang="uk" href="https://www.nailday.ee/uk" />
      <link rel="canonical" href={`https://www.nailday.ee/${language}`} />

      <title>
        NailDay —{" "}
        {translateString(
          "Top Nail Salon in Tallinn | Best Manicure & Nail Art"
        )}
      </title>
      <meta
        name="description"
        content={translateString(
          "Discover the top nail salon in Tallinn offering best manicure, extension, and nail art services. Enjoy expert gel nails, acrylic nails, and custom designs. Book your appointment today for a best nail care experience!"
        )}
      />
      <meta
        name="keywords"
        content={translateString(
          "nail salon Tallinn, manicure Tallinn, nailDay Tallinn, gel nails Tallinn, acrylic nails Tallinn, nail art Tallinn, best nail care Tallinn, best nail salon Tallinn, custom nail designs Tallinn, luxury nail salon Tallinn"
        )}
      />
      <meta
        name="author"
        content={translateString("NailDay Nail Salon in Tallinn")}
      />
      <meta name="robots" content="index, follow" />
      <meta
        property="og:title"
        content={translateString(
          "Top Nail Salon in Tallinn | Best Manicure & Nail Art Services"
        )}
      />
      <meta
        property="og:description"
        content={translateString(
          "Experience the best nail care services in Tallinn. Our salon offers best manicure, nailDay, gel nails, acrylic nails, and custom nail art. Book now for a top-notch nail salon experience!"
        )}
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.nailday.ee" />
      <meta property="og:image" content="https://www.nailday.ee/favicon.ico" />
      <meta
        property="og:site_name"
        content={translateString("NailDay Nail Salon in Tallinn")}
      />
    </Helmet>
  );
};

export default MetaSEOComponent;

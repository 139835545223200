import React, { useEffect, useRef, useState } from "react";
import LanguageIcon from "@mui/icons-material/Language";
import uaFlag from "../../assets/ua-lang.png";
import usFlag from "../../assets/usa-lang.png";
import rusFlag from "../../assets/rus-lang.png";
import eeFlag from "../../assets/ee-lang.png";
import { setLanguage } from "../../utils/utils";
import LanguageItem from "../languageItem/LanguageItem";
import "./LanguageDropdown.css";
import { Language } from "../../types/types";

interface LanguageDropdownProps {
  isMobileView: boolean;
  languageState: Language;
  setLanguageState: (language: Language) => void;
  isMenuOpen: boolean;
}

const LanguageDropdown: React.FC<LanguageDropdownProps> = ({
  isMobileView,
  languageState,
  setLanguageState,
  isMenuOpen,
}) => {
  const [isLanguageVisible, setIsLanguageOpen] = useState(false);
  const languageDropdownRef = useRef(null);

  useEffect(() => {
    setLanguage(languageState);
  }, [languageState]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        languageDropdownRef.current &&
        !languageDropdownRef.current.contains(e.target as Node)
      ) {
        setIsLanguageOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const handleLanguageChange = (newLanguage: Language) => {
    setLanguageState(newLanguage);
    setIsLanguageOpen(false);
  };

  return (
    <div
      ref={languageDropdownRef}
      className="languageDropdown-item-language-icon-div"
    >
      <div
        className="languageDropdown-item-language-icon"
        onClick={() => setIsLanguageOpen((prev) => !prev)}
      >
        {!isMobileView && <p>{languageState?.toUpperCase()}</p>}
        <LanguageIcon style={{ display: isMenuOpen ? "none" : "inline" }} />
      </div>
      {isLanguageVisible && (
        <div className={`languageDropdown-item-languages-web`}>
          <LanguageItem
            language="et"
            currentLanguage={languageState}
            flag={eeFlag}
            alt="Estonian language"
            onLanguageChange={handleLanguageChange}
          />
          <LanguageItem
            language="ru"
            currentLanguage={languageState}
            flag={rusFlag}
            alt="Russian language"
            onLanguageChange={handleLanguageChange}
          />
          <LanguageItem
            language="en"
            currentLanguage={languageState}
            flag={usFlag}
            alt="English language"
            onLanguageChange={handleLanguageChange}
          />
          <LanguageItem
            language="uk"
            currentLanguage={languageState}
            flag={uaFlag}
            alt="Ukrainian language"
            onLanguageChange={handleLanguageChange}
          />
        </div>
      )}
    </div>
  );
};

export default LanguageDropdown;

import "./About.css";
import image1 from "../../assets/nailday-watermark.png";
import image2 from "../../assets/diana.jpg";
import { translateString } from "../../utils/languageTranslations";
import AnimatedDiv from "../../utils/AnimatedDiv";
import AnimatedImage from "../../utils/AnimatedImage/AnimatedImage";

const About = ({ aboutRef }: { aboutRef: React.RefObject<HTMLDivElement> }) => {
  return (
    <section id="about" ref={aboutRef} className="about">
      <AnimatedDiv className="about-wrapper-main">
        <div className="about-wrapper-top">
          <h1>{translateString("About us")}</h1>
          <img
            className="about-image-watermark"
            src={image1}
            alt="Nailday watermark"
          />
        </div>
        <div className="about-wrapper">
          <AnimatedImage
            src={image2}
            alt="Diana Nail manicure master"
            className="about-image"
          />
          <div className="about-text">
            <p>{translateString("Hello! Let's get to know each other!")}</p>
            <p>
              {translateString(
                "I am Diana, a nail technician with more than 5 years of experience in the nail service industry."
              )}
            </p>
            <p>
              {translateString(
                "I look forward to meeting you soon and making your life brighter, simpler, and more comfortable, so that all passersby can't take their eyes off you."
              )}
            </p>
            <h2>Diana Zemskova</h2>
          </div>
        </div>
      </AnimatedDiv>
    </section>
  );
};

export default About;

import ErrorComponent from "../components/ErrorComponent/ErrorComponent";

const ErrorPage = () => {
  return (
    <>
      <ErrorComponent />
    </>
  );
};

export default ErrorPage;

import { useEffect } from "react";
import BookingCalendar from "../components/bookingCalendar/BookingCalendar";
import Contact from "../components/contact/Contact";
import ProgressBar from "../components/progressBar/ProgressBar";
import TopBarMinified from "../components/topBarMinified/TopBarMinified";
import { IUser, Language } from "../types/types";
import { useNavigate } from "react-router-dom";
import HomePage from "./HomePage";
import { getUserDataLS } from "../utils/utils";

const BookPageCalendar = ({
  progressBar,
  userData,
  setUserData,
  setProgressBar,
  languageState,
  setLanguageState,
}: {
  progressBar: number;
  userData: IUser;
  setUserData: (userData: IUser) => void;
  setProgressBar: (progressBar: number) => void;
  languageState: Language;
  setLanguageState: (language: Language) => void;
}) => {
  const { serviceName, serviceId } = userData;
  const localStorageUserData = getUserDataLS();

  const navigate = useNavigate();

  const isServiceSelected =
    (serviceName && serviceId) ||
    (localStorageUserData?.serviceNameLS && localStorageUserData?.serviceIdLS);

  useEffect(() => {
    !isServiceSelected && navigate("/");
    setProgressBar(1);
  }, []);

  if (!isServiceSelected) {
    return (
      <HomePage
        languageState={languageState}
        setLanguageState={setLanguageState}
      />
    );
  }

  return (
    <>
      <TopBarMinified />
      <ProgressBar progressBar={progressBar} />
      <BookingCalendar userData={userData} setUserData={setUserData} />
      <Contact />
    </>
  );
};

export default BookPageCalendar;

import React from "react";
import { motion } from "framer-motion";
import "./Home.css";
import AdjustIcon from "@mui/icons-material/Adjust";
import { Link } from "react-router-dom";
import { getLanguage } from "../../utils/utils";
import { translateString } from "../../utils/languageTranslations";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import useMobile from "../../hooks/useMobile";
import useScrollPosition from "../../hooks/useScrollPosition";
import InteractiveBubble from "../interactiveBubble/InteractiveBubble";
import main from "../../assets/main.png";
import TypewriterComponent from "typewriter-effect";
import useScrolledToBottom from "../../hooks/useScrollToBottom";
import AnimatedDiv from "../../utils/AnimatedDiv";

const Home = ({
  homeRef,
  isMenuOpen,
}: {
  homeRef?: React.RefObject<HTMLDivElement>;
  isMenuOpen: boolean;
}) => {
  const language = getLanguage();
  const isScrolledDown = useScrollPosition(window.innerHeight - 100);
  const isScrolledToBottom = useScrolledToBottom();
  const isMobileView = useMobile();

  return (
    <motion.div>
      <section id="home" ref={homeRef} className="home">
        <div className="home-main">
          <div className="home-main-wrapper">
            <div>
              <AnimatedDiv
                margin={"0px"}
                transition={{ delay: 0.25, duration: 0.5 }}
                className="home-main-text"
              >
                {translateString("Let your nails")}
                <TypewriterComponent
                  options={{
                    wrapperClassName: "typewriter",
                    strings: [
                      translateString("speak for you!"),
                      translateString("tell your story!"),
                      translateString("be your signature!"),
                      translateString("steal the spotlight!"),
                      translateString("turn heads everywhere!"),
                      translateString("shine like never before!"),
                      translateString("be a work of art!"),
                      translateString("reveal your true colors!"),
                    ],
                    autoStart: true,
                    loop: true,
                  }}
                />
              </AnimatedDiv>
              <AnimatedDiv
                margin={"0px"}
                transition={{ delay: 0.25, duration: 0.5 }}
                className="home-main-text-p"
              >
                <p>
                  {translateString(
                    "NailDay is a space of trends, minimalism, bright colors, and self-love."
                  )}
                </p>
                <p>
                  {translateString("Start your day with a perfect manicure.")}
                </p>
                <p>
                  {translateString(
                    "I will help break stereotypes about nail extensions, gel coatings, nail plate strengthening, and nail design."
                  )}
                </p>

                <br />
                <p>{translateString("Come and treat yourself today!")}</p>
              </AnimatedDiv>
            </div>
            <img className="home-main-image" src={main} alt="Main manicure" />
          </div>

          <AnimatedDiv
            transition={{ delay: 0.25, duration: 0.5 }}
            margin={"0px"}
          >
            <Link
              className="link home-main-button"
              to={`/${language}/${translateString("booking-service-select")}`}
            >
              <button>
                {translateString("Book Appointment")}
                <AdjustIcon className="home-main-icon-animate" />
              </button>
            </Link>
          </AnimatedDiv>
          {isMobileView &&
            isScrolledDown &&
            !isScrolledToBottom &&
            !isMenuOpen && (
              <Link
                className={`topBar-appointment-mobile link`}
                to={`/${language}/${translateString("booking-service-select")}`}
              >
                <div className="topBar-appointment-mobile-wrapper">
                  {translateString("Book Now")}
                  <TouchAppIcon className="topBar-appointment-mobile-icon" />
                </div>
              </Link>
            )}
        </div>
      </section>
      <InteractiveBubble />
    </motion.div>
  );
};

export default Home;

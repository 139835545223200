import "./Ratings.css";
import manicureSaloon from "../../assets/manicure-saloon.jpg";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import { translateString } from "../../utils/languageTranslations";
import AnimatedDiv from "../../utils/AnimatedDiv";

const Ratings = ({
  reviewsRef,
}: {
  reviewsRef: React.RefObject<HTMLDivElement>;
}) => {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  return (
    <section ref={ref} className="ratings">
      <AnimatedDiv className="ratings-wrapper">
        <img loading="lazy" src={manicureSaloon} alt="Manicure Salon Talin" />
        <div ref={reviewsRef} className="ratingsWrapper">
          <div>
            <h1 className="ratings-h1">
              {translateString("Satisfied customers")}
            </h1>
          </div>
          <div className="ratings-card-wrapper">
            <div className="ratings-card">
              <h1>
                <CountUp redraw={inView} end={600} />+
              </h1>
              <p>{translateString("Happy customers")}</p>
            </div>
            <div className="ratings-card">
              <h1>
                <CountUp redraw={inView} end={82} />%
              </h1>
              <p>{translateString("Rate of returned customers")}</p>
            </div>
            <div className="ratings-card">
              <h1>
                <CountUp redraw={inView} end={6000} />+
              </h1>
              <p>{translateString("Happy nails")}</p>
            </div>
            <div className="ratings-card">
              <h1>
                <CountUp redraw={inView} end={93} smartEasingAmount={1} />
                /100%
              </h1>
              <p>{translateString("Customer reviews")}</p>
            </div>
          </div>
        </div>
      </AnimatedDiv>
    </section>
  );
};

export default Ratings;

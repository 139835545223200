import { useEffect } from "react";
import BookingService from "../components/bookingService/BookingService";
import Contact from "../components/contact/Contact";
import ProgressBar from "../components/progressBar/ProgressBar";
import TopBarMinified from "../components/topBarMinified/TopBarMinified";
import { IUser, Language } from "../types/types";
import { useLocation } from "react-router-dom";
import { setLanguage } from "../utils/utils";
import { VALID_LANGUAGES } from "../constants/constants";

const BookPageService = ({
  progressBar,
  setProgressBar,
  userData,
  setUserData,
  setLanguageState,
}: {
  progressBar: number;
  setProgressBar: (progressBar: number) => void;
  userData: IUser;
  setUserData: (userData: IUser) => void;
  setLanguageState: (language: Language) => void;
}) => {
  useEffect(() => {
    setProgressBar(0);
  }, []);

  const location = useLocation();

  const setLanguageStateFromUrl = () => {
    const language = location.pathname.split("/")[1] as Language;
    if (VALID_LANGUAGES.includes(language)) {
      setLanguage(language);
      setLanguageState(language);
    }
  };
  useEffect(() => {
    setLanguageStateFromUrl();
  }, [location]);

  return (
    <>
      <TopBarMinified />
      <ProgressBar progressBar={progressBar} />
      <BookingService userData={userData} setUserData={setUserData} />
      <Contact />
    </>
  );
};

export default BookPageService;

import { useEffect } from "react";
import Contact from "../components/contact/Contact";
import ProgressBar from "../components/progressBar/ProgressBar";
import TopBarMinified from "../components/topBarMinified/TopBarMinified";
import BookingConfirm from "../components/bookingConfirm/BookingConfirm";
import { IUser, Language } from "../types/types";
import { useNavigate } from "react-router-dom";
import HomePage from "./HomePage";

const BookPageConfirm = ({
  progressBar,
  setProgressBar,
  userData,
  languageState,
  setLanguageState,
}: {
  progressBar: number;
  setProgressBar: (progressBar: number) => void;
  userData: IUser;
  languageState: Language;
  setLanguageState: (language: Language) => void;
}) => {
  const navigate = useNavigate();
  const { serviceName, serviceId, date, time, name, phone } = userData;

  const shouldNavigate =
    !serviceName || !serviceId || !date || !time || !name || !phone;

  useEffect(() => {
    shouldNavigate && navigate("/");
    setProgressBar(3);
  }, []);

  if (shouldNavigate) {
    return (
      <HomePage
        languageState={languageState}
        setLanguageState={setLanguageState}
      />
    );
  }

  return (
    <>
      <TopBarMinified />
      <ProgressBar progressBar={progressBar} />
      <BookingConfirm userData={userData} />
      <Contact />
    </>
  );
};

export default BookPageConfirm;

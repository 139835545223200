// CarouselComponent.jsx
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CarouselComponent.css";
import img1 from "../../assets/carousel/p1.jpeg";
import img2 from "../../assets/carousel/p2.jpeg";
import img3 from "../../assets/carousel/p3.jpeg";
import img4 from "../../assets/carousel/p4.jpeg";
import img5 from "../../assets/carousel/p5.jpeg";
import img6 from "../../assets/carousel/p6.jpeg";
import img7 from "../../assets/carousel/p7.jpeg";
import img8 from "../../assets/carousel/p8.jpeg";
import img9 from "../../assets/carousel/p9.jpeg";
import img10 from "../../assets/carousel/p10.jpeg";
import img11 from "../../assets/carousel/p11.jpeg";
import img12 from "../../assets/carousel/p12.jpeg";
import naildayWatermark from "../../assets/nailday-watermark.png";
import useMobile from "../../hooks/useMobile";
import { translateString } from "../../utils/languageTranslations";
import AnimatedDiv from "../../utils/AnimatedDiv";

const images = [
  img3,
  img1,
  img5,
  img2,
  img10,
  img6,
  img7,
  img8,
  img9,
  img4,
  img11,
  img12,
];

const CarouselComponent = ({
  carouselRef,
}: {
  carouselRef: React.RefObject<HTMLDivElement>;
}) => {
  const isMobileView = useMobile();

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: isMobileView ? 1 : 3,
    speed: 250,
    autoplaySpeed: 2500,
    arrows: true,
    autoplay: true,
    dots: true,
  };

  return (
    <AnimatedDiv>
      <section id="images" className="carouselComponent" ref={carouselRef}>
        <div className="carouselComponent-top">
          <h1> {translateString("Gallery")} </h1>
          <img src={naildayWatermark} alt="Nailday watermark logo" />
        </div>
        <Slider {...settings}>
          {images.map((image, index) => (
            <div className="carousel-slide" key={index}>
              <img
                loading="lazy"
                src={image}
                alt={`Manicure service ${index + 1}`}
                style={{ width: "90%" }}
              />
              <div className="carousel-background">
                <span>NailDay</span>
              </div>
            </div>
          ))}
        </Slider>
      </section>
    </AnimatedDiv>
  );
};

export default CarouselComponent;

import "./BookingDetail.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { IUser } from "../../types/types";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { translateString } from "../../utils/languageTranslations";
import { getLanguage } from "../../utils/utils";
import { postData } from "../../utils/fetchData";
import { InvalidateQueryFilters, useQueryClient } from "@tanstack/react-query";

const BookingDetail = ({
  userData,
  setUserData,
}: {
  userData: IUser;
  setUserData: (userData: IUser) => void;
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const language = getLanguage();
  const queryClient = useQueryClient();

  const invalidateMultipleQueries = async () => {
    await Promise.all([
      queryClient.removeQueries("allServices" as InvalidateQueryFilters),
      queryClient.removeQueries("allTimeslots" as InvalidateQueryFilters),
    ]);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Too Short, enter your full name!")
      .max(50, "Too Long!")
      .required("Required"),
    phone: Yup.string()
      .transform((value) => value.replace(/\s/g, ""))
      .matches(
        /^(\+\d{1,3}[-]?)?\d{7,8}$/,
        "Must be a valid phone number! (e.g. +372 12345678)"
      )
      .required("Required"),
    email: Yup.string()
      .email("Invalid email")
      .test(
        "email",
        "Must be a valid email address and not a custom domain! (e.g. example@gmail.com)",
        (value) => {
          const regex =
            /@(gmail|yandex|hotmail|outlook|icloud|mail|aol|zoho|yahoo|online|vk|hot)\.(com|ee|ru|tr)$/;
          return regex.test(value);
        }
      )
      .required("Required"),
    comments: Yup.string().optional(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      comments: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);

        const response = await postData("bookings", {
          ...userData,
          ...values,
          time: userData.time.startTimeslotIds,
        });

        setUserData({ ...userData, ...values });
        setLoading(false);
        invalidateMultipleQueries();
        navigate(`/${language}/${translateString("booking-confirmation")}`);
        return response;
      } catch (error) {
        navigate("/book-fail");
      }
    },
  });

  const isButtonDisabled =
    Object.keys(formik.errors).length > 0 || !formik.dirty;

  return (
    <div className="bookingDetail">
      <form className={"bookingDetail-error"} onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id="name"
          name="name"
          label={translateString("Name")}
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          margin={"dense"}
          style={{
            height: "85px",
          }}
        />
        <TextField
          fullWidth
          id="email"
          name="email"
          label={translateString("Email")}
          type="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          margin={"dense"}
          style={{
            height: "85px",
          }}
        />
        <TextField
          fullWidth
          id="phone"
          name="phone"
          label={translateString("Phone")}
          type="tel"
          value={formik.values.phone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
          margin={"dense"}
          style={{
            height: "85px",
          }}
        />
        <TextField
          fullWidth
          id="comments"
          name="comments"
          label={translateString("Comments/Remarks (optional)")}
          type="text"
          value={formik.values.comments}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.comments && Boolean(formik.errors.comments)}
          helperText={formik.touched.comments && formik.errors.comments}
          margin={"dense"}
          style={{
            height: "85px",
          }}
        />
        <Button
          disabled={isButtonDisabled}
          color="success"
          variant="contained"
          fullWidth
          type="submit"
        >
          {loading ? (
            <ClipLoader size={20} color="white" />
          ) : (
            translateString("BOOK")
          )}
        </Button>
      </form>
    </div>
  );
};

export default BookingDetail;

// SchemaMarkup.js
import React from "react";
import { Helmet } from "react-helmet-async";

const SchemaMarkup = () => {
  const schema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "NailDay",
    url: "https://nailday.ee",
    logo: "https://nailday.ee/logo.png",
    image: "https://nailday.ee/image.jpg",
    description: "NailDay offers professional nail care services.",
    address: {
      "@type": "PostalAddress",
      addressLocality: "Tallinn",
      addressCountry: "Estonia",
    },
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+372 58 33 12 28",
      contactType: "Customer Service",
      areaServed: "Tallinn, Estonia",
      availableLanguage: ["en", "ru", "uk", "et"],
    },
    openingHoursSpecification: [
      {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ],
        opens: "10:00",
        closes: "20:00",
      },
    ],
    sameAs: [
      "https://www.facebook.com/profile.php?id=100041694949924",
      "https://www.instagram.com/nailday.ee/",
    ],
    potentialAction: [
      {
        "@type": "ReserveAction",
        target: {
          "@type": "EntryPoint",
          urlTemplate: "https://nailday.ee/en/booking-service-select",
          inLanguage: "en",
          actionPlatform: [
            "http://schema.org/DesktopWebPlatform",
            "http://schema.org/MobileWebPlatform",
          ],
        },
      },
      {
        "@type": "ReserveAction",
        target: {
          "@type": "EntryPoint",
          urlTemplate: "https://nailday.ee/ru/vybor-bronirovaniya-uslugi",
          inLanguage: "ru",
          actionPlatform: [
            "http://schema.org/DesktopWebPlatform",
            "http://schema.org/MobileWebPlatform",
          ],
        },
      },
      {
        "@type": "ReserveAction",
        target: {
          "@type": "EntryPoint",
          urlTemplate: "https://nailday.ee/uk/vybir-poslugy-bronyuvannya",
          inLanguage: "uk",
          actionPlatform: [
            "http://schema.org/DesktopWebPlatform",
            "http://schema.org/MobileWebPlatform",
          ],
        },
      },
      {
        "@type": "ReserveAction",
        target: {
          "@type": "EntryPoint",
          urlTemplate: "https://nailday.ee/et/broneeri-teenuse-valik",
          inLanguage: "et",
          actionPlatform: [
            "http://schema.org/DesktopWebPlatform",
            "http://schema.org/MobileWebPlatform",
          ],
        },
      },
    ],
  };

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  );
};

export default SchemaMarkup;

// LanguageItem.tsx
import React from "react";
import "./LanguageItem.css";
import { useNavigate } from "react-router-dom";
import { Language } from "../../types/types";

interface LanguageItemProps {
  language: Language;
  currentLanguage: Language;
  flag: string;
  alt: string;
  onLanguageChange: (language: Language) => void;
}

const LanguageItem: React.FC<LanguageItemProps> = ({
  language,
  currentLanguage,
  flag,
  alt,
  onLanguageChange,
}) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        onLanguageChange(language);
        if (language === "et") {
          navigate("/", { replace: true });
          window.location.reload();
          return;
        }
        navigate(`/${language}`, { replace: true });
        window.location.reload();
      }}
      className={`languageItem ${
        currentLanguage === language ? "selected" : ""
      }`}
    >
      <img
        loading="lazy"
        className={
          currentLanguage === language
            ? "languageItem-icon-selected"
            : "languageItem-icon"
        }
        src={flag}
        alt={alt}
      />
      {language.toUpperCase()}
    </div>
  );
};

export default LanguageItem;

import { useState, useEffect, useRef, Suspense } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const LazyLoad = ({ children, rootMargin = "200px" }) => {
  const [isVisible, setIsVisible] = useState(false);
  const observerRef = useRef(null);
  const componentRef = useRef(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observerRef.current.disconnect();
        }
      },
      {
        rootMargin, // This sets the buffer
      }
    );

    if (componentRef.current) {
      observerRef.current.observe(componentRef.current);
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [rootMargin]);

  return (
    <div ref={componentRef} style={{ minHeight: "50vh" }}>
      {isVisible && (
        <Suspense fallback={<Skeleton height={"50vh"} width={"100vw"} />}>
          {children}
        </Suspense>
      )}
    </div>
  );
};

export default LazyLoad;

import "./TopBar.css";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import logo2 from "../../assets/logo-mini.png";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { useEffect, useRef, useState } from "react";
import useMobile from "../../hooks/useMobile";
import LanguageDropdown from "../languageDropdown/LanguageDropdown";
import { translateString } from "../../utils/languageTranslations";
import { getLanguage } from "../../utils/utils";
import useScrollPosition from "../../hooks/useScrollPosition";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";

const TopBar = ({
  scrollToContent,
  languageState,
  setLanguageState,
  isMenuOpen,
  setIsMenuOpen,
  breadcrumbs,
}) => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const isMobileView = useMobile();
  const language = getLanguage();
  const isScrolledDown = useScrollPosition();
  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <header className={`topBar ${isScrolledDown && "topBar-shadow"}`}>
      <div className="topBar-logo">
        <img
          srcSet={`
                  ${logo2} 1200w,
                  ${logo} 2000w
                `}
          onClick={() =>
            scrollToContent(
              breadcrumbs.find((b) => b.id === "home").refObj,
              "home"
            )
          }
          src={logo2}
          alt="TopBar logo"
        />
      </div>
      <div className="menu-button-wrapper">
        {isMobileView && (
          <LanguageDropdown
            isMobileView={isMobileView}
            languageState={languageState}
            setLanguageState={setLanguageState}
            isMenuOpen={isMenuOpen}
          />
        )}
        <div className="menu-button" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          {isMenuOpen ? (
            <CloseIcon fontSize="large" />
          ) : (
            <MenuIcon fontSize="large" />
          )}
        </div>
      </div>

      <nav className="topbBar-nav">
        <ul
          ref={modalRef}
          className={`topBar-menu ${isMenuOpen ? "open" : ""}`}
        >
          {breadcrumbs.map((item) => (
            <li
              key={item.id}
              className="topBar-item"
              onMouseEnter={() => setHoveredItem(item.label)}
              onMouseLeave={() => setHoveredItem(null)}
              onClick={() => {
                scrollToContent(item.refObj, item.id);
                setIsMenuOpen(false);
              }}
            >
              {(hoveredItem === item.label && item.icon) ||
                (isMobileView && item.icon)}
              {item.label}
            </li>
          ))}

          <li
            className={`${isMobileView ? "topBar-item" : "topBar-item-last"} `}
          >
            {isMobileView && <EventAvailableIcon />}
            {isMobileView && (
              <Link
                className={`${!isMobileView && "topBar-appointment"}  link ${
                  isScrolledDown && !isMobileView
                    ? "topBar-appointment-animation"
                    : ""
                }`}
                to={`/${language}/${translateString("booking-service-select")}`}
              >
                {translateString("Book Now")}
              </Link>
            )}
            {!isMobileView && (
              <LanguageDropdown
                isMobileView={isMobileView}
                languageState={languageState}
                setLanguageState={setLanguageState}
                isMenuOpen={isMenuOpen}
              />
            )}
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default TopBar;

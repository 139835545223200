import { VALID_LANGUAGES } from "../constants/constants";
import { Language } from "../types/types";
import { translateString } from "./languageTranslations";

export function getDateMonthsAhead(months: number): Date {
  const date = new Date();
  date.setMonth(date.getMonth() + months);
  return date;
}

export function convertMinutesToHours(
  minutes: number,
  format: "short" | "long" = "short"
): string {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  let result = "";

  if (hours > 0) {
    const hoursKey =
      hours === 1 ? `hours-${format}-singular` : `hours-${format}-plural`;
    result += `${hours} ${translateString(hoursKey)}`;
  }
  if (remainingMinutes > 0) {
    const minutesKey = format === "short" ? "minutes-short" : "minutes-long";
    result += ` ${remainingMinutes} ${translateString(minutesKey)}`;
  }

  return result.trim();
}

export function setLanguage(language: Language): void {
  localStorage.setItem("nailday-site-language", language);
}

export function getLanguage(): Language | any | null {
  return localStorage.getItem("nailday-site-language");
}

export function generateGoogleCalendarLink(
  title: string,
  startDate: string,
  startTime: string,
  endTime: string,
  description: string,
  location: string
): string {
  const baseUrl = "https://www.google.com/calendar/render?action=TEMPLATE";

  // Convert start and end time to Google Calendar's required format
  const formattedStartTime = startTime.replace(":", "") + "00"; // HHMMSS format
  const formattedEndTime = endTime.replace(":", "") + "00"; // HHMMSS format

  // Combine date and time for start and end
  const startDateTime =
    startDate.replace(/-/g, "") + "T" + formattedStartTime + "Z";
  const endDateTime =
    startDate.replace(/-/g, "") + "T" + formattedEndTime + "Z";

  const params = new URLSearchParams({
    text: title,
    dates: `${startDateTime}/${endDateTime}`,
    details: description,
    location: location,
  });

  return `${baseUrl}&${params.toString()}`;
}

export const setUserDataLS = (userData: any) => {
  localStorage.setItem("nailday-user-data", JSON.stringify(userData));
};

export const getUserDataLS = () => {
  const userData = localStorage.getItem("nailday-user-data");
  return userData ? JSON.parse(userData) : null;
};

export const setLanguageStateFromUrl = (location, setLanguageState) => {
  const language = location.pathname.split("/")[1] as Language;
  if (VALID_LANGUAGES.includes(language)) {
    setLanguage(language);
    setLanguageState(language);
  }
};

import "./ProgressBar.css";
import DateRangeIcon from "@mui/icons-material/DateRange";

import ListIcon from "@mui/icons-material/List";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { translateString } from "../../utils/languageTranslations";

const PROGRESS_BAR_TEXT = [
  "Choose a service",
  "Pick a time",
  "Enter your details",
  "Booking successful",
];

const ProgressBar = ({ progressBar }: { progressBar: number }) => (
  <div className="progressBar">
    <h1>{translateString(PROGRESS_BAR_TEXT[progressBar])}</h1>
    <div className="stepper-wrapper">
      <div
        className={progressBar >= 0 ? "stepper-item completed" : "stepper-item"}
      >
        <div className="step-counter">
          <ListIcon />
        </div>
        <div className={progressBar >= 0 ? "step-name-complete" : "step-name"}>
          {translateString("Service")}
        </div>
      </div>
      <div
        className={progressBar >= 1 ? "stepper-item completed" : "stepper-item"}
      >
        <div className="step-counter">
          <DateRangeIcon />
        </div>
        <div className={progressBar >= 1 ? "step-name-complete" : "step-name"}>
          {translateString("Time")}
        </div>
      </div>
      <div
        className={progressBar >= 2 ? "stepper-item completed" : "stepper-item"}
      >
        <div className="step-counter">
          <ContactPhoneIcon />
        </div>
        <div className={progressBar >= 2 ? "step-name-complete" : "step-name"}>
          {translateString("Details")}
        </div>
      </div>
      <div
        className={progressBar >= 3 ? "stepper-item completed" : "stepper-item"}
      >
        <div className="step-counter">
          <CheckBoxIcon />
        </div>
        <div className={progressBar >= 3 ? "step-name-complete" : "step-name"}>
          {translateString("Success")}
        </div>
      </div>
    </div>
  </div>
);

export default ProgressBar;

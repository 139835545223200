import React, { useRef } from "react";
import { motion, Variants, useAnimation, useInView } from "framer-motion";
import "./AnimatedImage.css"; // Ensure to import the CSS file

type AnimatedImageProps = {
  src: string;
  alt: string;
  variants?: Variants;
  transition?: any;
  className?: string;
  margin?: any;
  loading?: "eager" | "lazy";
  style?: React.CSSProperties;
};

const defaultVariants: Variants = {
  hidden: { scale: 1 },
  visible: { scale: 1.2 },
};

const AnimatedImage: React.FC<AnimatedImageProps> = ({
  src,
  alt,
  variants = defaultVariants,
  transition = { delay: 0.3, duration: 2.5 },
  className,
  margin = "-100px",
  loading,
  style,
}) => {
  const ref = useRef<HTMLImageElement | null>(null);
  const inView = useInView(ref, { once: true, margin });
  const controls = useAnimation();

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <div className="zoom-container" style={style}>
      <motion.img
        ref={ref}
        src={src}
        alt={alt}
        variants={variants}
        initial="hidden"
        animate={controls}
        transition={transition}
        className={`zoom-image ${className}`}
        loading={loading}
      />
    </div>
  );
};

export default AnimatedImage;

import { useNavigate } from "react-router-dom";
import "./ErrorComponent.css";
import ErrorIcon from "@mui/icons-material/Error";
import { useEffect, useState } from "react";
import TopBarMinified from "../topBarMinified/TopBarMinified";
import { translateString } from "../../utils/languageTranslations";

const ErrorComponent = () => {
  const navigate = useNavigate();
  const [timer, setTimer] = useState(5);

  useEffect(() => {
    setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1000);
  }, []);

  if (timer < 1) {
    navigate("/");
  }

  return (
    <>
      <TopBarMinified />
      <div className="error-container" role="alert">
        <ErrorIcon className="error-icon" color="error" />
        <h1>{translateString("Oops! You shouldn't be here.")}</h1>
        <p>
          {translateString("You will be redirected back to homepage in")}{" "}
          <span>{timer}</span> {translateString("seconds")}{" "}
        </p>
        <p>{translateString("Something went wrong.")}</p>
        <p>{translateString("Don't worry, our team is on it!")}</p>
        <button onClick={() => navigate("/")}>
          {translateString("Go Back")}
        </button>
      </div>
    </>
  );
};

export default ErrorComponent;
